import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/blogPost_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "a-range-of-participation",
      "style": {
        "position": "relative"
      }
    }}>{`A Range of Participation`}<a parentName="h1" {...{
        "href": "#a-range-of-participation",
        "aria-label": "a range of participation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p><a parentName="p" {...{
        "href": "https://zuzalu.city/"
      }}>{`Zuzalu`}</a>{` is a fascinating experiment in letting people who know each other online arrange themselves in physical space. Given that many relationships became primarily digital during COVID, Zuzalu has the chance to set some precedents for what these kinds of communities and events will look like in the years to come.`}</p>
    <p>{`In short: it was not perfect, but it was very lovely. The single biggest learning: we can improve how we facilitate one another. We’re still largely stuck in the single-speaker or small-panel style of sharing information, which sucks. `}</p>
    <p>{`We gathered a great deal of creativity and intellect, and then often put people in rooms where they listened passively. The goal of facilitation is, to me, very clear: `}<strong parentName="p">{`no more audiences`}</strong>{`. That said, environments like Zuzalu require some “activation energy” to gather such creativity. This energy is provided by the fact that people like Vitalik and David Hoffman appeared for two months in the same place, invited people they respected as experts in different fields, and fed us vegan desserts or peer pressured us into cold plunges.`}</p>
    <p>{`It is that very same activation energy which makes facilitation hard, because we come in with the expectation that listening to Vitalik speak is more valuable than talking to a stranger. This is obviously untrue if you’ve ever had a meaningful conversation with someone you don’t know, or have tried to follow one of Vitalik’s random tangents, but we are misled by the evolutionary machinery in our brains which is highly attuned to social status.`}</p>
    <p>{`Good facilitation breaks the expectation that the most value you can get from an hour is in listening to high status people by gently weaving everyone into smaller conversations likely to prove meaningful, and reminding people that you’re far better off reading `}<a parentName="p" {...{
        "href": "https://vitalik.eth.limo/"
      }}>{`vitalik.eth.limo`}</a>{` or listening to `}<a parentName="p" {...{
        "href": "https://www.bankless.com/listen"
      }}>{`Bankless`}</a>{` if you really want to learn from those kind of people. `}</p>
    <p>{`We need to orient people towards each other, rather than at a perceived authority. This amounts to rewiring our perception of who and what is valuable, which is exactly the same work we do when we write meaningful economic code capable of `}<a parentName="p" {...{
        "href": "/learn/module-5/incentives"
      }}>{`incentivising different social structures`}</a>{`.`}</p>
    <p>{`The conversations which will define what Zuzalu becomes were had over communal breakfast, or in the water, or on the trails, or in small apartments over home-cooked food and great jokes about the failure of most Balkan people to spice their food to African or Asian standards. It’s worth reflecting on this fact and bringing it more explicitly into the structure of future gatherings.`}</p>
    <h2 {...{
      "id": "questioning-authority",
      "style": {
        "position": "relative"
      }
    }}>{`Questioning Authority`}<a parentName="h2" {...{
        "href": "#questioning-authority",
        "aria-label": "questioning authority permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Zuzalu raised two related questions for me: “What is a network state? Do we place emphasis on the word ‘network’ or ‘state’?” `}</p>
    <p>{`The words we choose matter, as does the degree to which we are conscious of the many meanings each one carries. `}</p>
    <p>{`If we are primarily a network, then the meanings of the word “state” open up significantly to include every verifiable transition which has resulted in the total arrangement of information we share right now. It brings us much closer to the verb form of the word, as in “Please state your case”, which means to express information clearly and carefully.`}</p>
    <p>{`If we are primarily a state–a nation or territory considered as an organised political community under one government–then the network becomes a means to an end, where the end is political organisation. It is more about the arrangement of people than information.`}</p>
    <p>{`Considered together, network states are interesting because they seem to promise the combined arrangement of information and people in ways previously not possible. At best, they might enable us to `}<a parentName="p" {...{
        "href": "/conversation/hospitality/#playful-souls"
      }}>{`imagine and live within more creative social forms once more`}</a>{`. `}</p>
    <h2 {...{
      "id": "violence-or-volition",
      "style": {
        "position": "relative"
      }
    }}>{`Violence or Volition`}<a parentName="h2" {...{
        "href": "#violence-or-volition",
        "aria-label": "violence or volition permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`However, arranging information can–in certain contexts–become propaganda and organising people can–in those same contexts–become tyranny. In fact, there is a negative feedback loop at work here. When we can’t arrange information persuasively, we tend to resort to violence in order to organise people, and more violent states tend to arrange information in ways which people are both incentivised to believe and to police in others.`}</p>
    <p>{`It is critical that the negative spiral into violence begins largely with an inability to arrange information in a persuasive manner, because it raises the question, “What means do we have to arrange information persuasively without being necessarily violent?”. `}</p>
    <p>{`One suggestion blockchains have led to is “`}<a parentName="p" {...{
        "href": "https://nakamoto.com/credible-neutrality/"
      }}>{`credible neutrality`}</a>{`”. If everyone can participate in the network, and if every state transition adheres to rules we can all verify independently, then the resultant state we share is persuasive without anyone needing to enforce it.`}</p>
    <p>{`This is not a new idea. It is present in nascent form in the Bitcoin whitepaper, and is the natural outcome of a network of timestamp servers which establish novel peer-to-peer routines that solve very old communication problems. Perhaps that’s one reason why Satoshi placed an overtly political statement in the Genesis Block: “The Times 03/Jan/2009 Chancellor on brink of second bailout for banks.”`}</p>
    <p>{`Notice how credible neutrality is `}<em parentName="p">{`not`}</em>{` political neutrality. Credible neutrality is operational: it has to do with how blockchains work intrinsically. Considered in the wider political environment within which they operate, blockchains are clearly not neutral technologies.`}</p>
    <h2 {...{
      "id": "a-persuasive-politics-of-participation",
      "style": {
        "position": "relative"
      }
    }}>{`A Persuasive Politics of Participation`}<a parentName="h2" {...{
        "href": "#a-persuasive-politics-of-participation",
        "aria-label": "a persuasive politics of participation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`So, what are the politics of blockchains? Being able to arrange information in more persuasive, open, credibly neutral ways means that we don’t often stop to ask what is really persuasive about this means of arrangement.`}</p>
    <p>{`Satoshi’s suggestion is at once simple and fractal. `}<strong parentName="p">{`Anyone who is participating fully can arrange information in any manner they please`}</strong>{`. It may seem strange to phrase it like this, but it’s true, because ‘participating fully’ entails running software and doing work (or risking value) that adheres to shared constraints in unambiguous ways. Once you’re doing that–once you’re using the same protocol or “speaking the common tongue”–and are awarded the right to change the network’s state, you can order the sequence of events which make up such a state transition in any way you like.`}</p>
    <p>{`This works well at the level of information, where arrangement is done in binary and has an entirely deterministic result we can all verify independently. Arranging people is more difficult, and so we often revert to old ideas about control and ownership and territory and leadership, rather than exploring the `}<a parentName="p" {...{
        "href": "https://sign.kernel.community"
      }}>{`horizons of the possible`}</a>{`. Zuzalu is hopefully just the beginning of what such explorations will look like.`}</p>
    <p>{`Part of the joy of such gatherings is that people cannot be arranged like information. There needs to be ambiguity and space for change in any healthy social structure. When such space is held open, it becomes obvious that `}<strong parentName="p">{`we the people arrange ourselves`}</strong>{` given the chance to do so. We arrange ourselves by choosing our relationships.`}</p>
    <p>{`You know this already. I may never fully understand you, but show me the five people you share most of your time with and I can learn everything I need to know about you.`}</p>
    <p>{`Information on a blockchain is persuasive because anyone who is participating fully can arrange it how they please and no-one needs to resort to physical violence. Organisations which do not resort to violence are persuasive when `}<strong parentName="p">{`people can arrange themselves`}</strong>{` by virtue of whom they participate with and how they choose to do so.`}</p>
    <p>{`What is radical about this is not greater efficiency, or more room for experimentation outside slow-moving and bureaucratic structures, or financializing everything. `}<a parentName="p" {...{
        "href": "/learn/module-4/governance/#anarchy"
      }}>{`It is certainly not about building new tools to govern others`}</a>{`. It is about enabling people to choose who they arrange themselves with. It is about cultivating `}<strong parentName="p">{`interdependence`}</strong>{`. `}</p>
    <p>{`Just like good facilitators can orient people towards one another, we can live in ways which focus on who we choose to participate with and how we are when doing so. This orientation towards the people we bind our lives with, rather than towards the abstract idealisation of structures for governing other people is one way towards wholesome networked states.`}</p>
    <p><em parentName="p">{`True leaders`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`are hardly known to their followers.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Next after them are the leaders`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`the people know and admire;`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`after them, those they fear;`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`after them, those they despise.`}</em>{`  `}</p>
    <p><em parentName="p">{`To give no trust`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`is to get no trust.`}</em>{`  `}</p>
    <p><em parentName="p">{`When the work’s done right,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`with no fuss or boasting,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`ordinary people say,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Oh, we did it.`}</em>{`  `}</p>
    <p><em parentName="p">{`–`}</em>{` `}<a parentName="p" {...{
        "href": "https://terebess.hu/english/tao/LeGuin.pdf"
      }}>{`Lao Tzu, via Ursula Le Guin`}</a></p>
    <p>{`This ancient verse describes well the radical politics of blockchains, which arrange information in a credibly neutral network that enables participants to arrange how we organise and who we relate with in ways that genuinely persuade our peers, rather than violently coerce them. `}</p>
    <p>{`Remember that ‘radical’ means ‘root’, because it points at that which anchors us in reality, giving meaning and nourishment to our lives: one another.`}</p>
    <p><em parentName="p">{`The return to the root`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`is peace.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Peace: to accept what must be,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`to know what endures.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`In that knowledge is wisdom.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Without it, ruin, disorder.`}</em>{`  `}</p>
    <p><em parentName="p">{`To know what endures`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`is to be openhearted,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`magnanimous,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`regal,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`blessed,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`following the Tao,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`the way that endures forever.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`The body comes to its ending,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`but there is nothing to fear.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      